import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import RouteWithAuth from '../components/RouteWithAuth';
import Widget from './Widget/Widget';
import GivingFlow from './GivingFlow/GivingFlow';
import ThankYou from './ThankYou/ThankYou';
import SignIn from './SignIn/SignIn';
import Account from './Account/Account';
import posed, { PoseGroup } from 'react-pose';

const AppContainer = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

const RoutesContainer = posed.div({
  enter: {
    opacity: 1,
    delay: 300,
    beforeChildren: true,
  },
  exit: { opacity: 0 },
});

const _Routes = ({ location, setTheme }) => (
  // If animating more than one child, each needs a `key`
  <Switch location={location}>
    <Route exact path="/widget" render={props => <Widget {...props} setTheme={setTheme} />} />
    <Route
      render={() => (
        <AppContainer initialPose="exit" pose="enter" key="app">
          <PoseGroup>
            <RoutesContainer key={location.pathname}>
              <Switch location={location}>
                <Route
                  exact
                  path="/"
                  render={() => {
                    window.location = 'https://www.rebelgive.com/';
                    return null;
                  }}
                />
                <RouteWithAuth
                  exact
                  path="/flows/:id"
                  render={props => <GivingFlow {...props} setTheme={setTheme} />}
                />
                <RouteWithAuth exact path="/thank-you" component={ThankYou} />
                <RouteWithAuth exact path="/sign-in" component={SignIn} />
                <RouteWithAuth exact path="/my-account" component={Account} />
                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </RoutesContainer>
          </PoseGroup>
        </AppContainer>
      )}
    />
  </Switch>
);

export const Routes = withRouter(_Routes);
