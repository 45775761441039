import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Footer, Progress, L3, T2 } from '@foyyay/flow-elements';

export default class FooterComponent extends Component {
  static propTypes = {
    value: PropTypes.number,
    max: PropTypes.number,
    hideProgress: PropTypes.bool,
  };

  render() {
    let showProgress = !this.props.hideProgress && !!this.props.max;
    return (
      <Footer>
        {showProgress && (
          <Footer.ProgressContainer>
            <Progress
              onGiveNowClick={this.props.handleGiveNowClick}
              value={this.props.value}
              max={this.props.max}
            >
              Give Now
            </Progress>
          </Footer.ProgressContainer>
        )}
        <Footer.TextContainer>
          <L3 style={{ marginBottom: '0.3rem' }}>
            <a href="https://www.rebelgive.com" target="_blank" rel="noreferrer noopener">
              Powered by RebelGive
            </a>
          </L3>
          <T2>
            <a href="https://www.rebelgive.com/legal" target="_blank" rel="noreferrer noopener">Secure & encrypted. By using you agree to our terms.</a>
          </T2>
        </Footer.TextContainer>
      </Footer>
    );
  }
}
