function makeMemoryStorage() {
  let _keys = [];
  let _data = {};

  let serialize = () => {};

  // Return the Nth key
  let key = n => {
    return _keys[n] || null;
  };

  // return the value for given key
  let getItem = key => {
    key = String(key);
    if (_data.hasOwnProperty(key)) {
      return _data[key];
    }
    return null;
  };

  // store a value at key
  let setItem = (key, value) => {
    key = String(key);
    value = String(value);
    _data[key] = value;
    if (!_keys.includes(key)) {
      _keys.push(key);
    }
    serialize();
  };

  // remove the value for key
  let removeItem = key => {
    key = String(key);
    let exists = false;
    if (_data.hasOwnProperty(key)) {
      exists = true;
    }
    delete _data[key];
    let index = _keys.indexOf(key);
    if (index > -1) {
      _keys.splice(index, 1);
    }
    if (exists) {
      serialize();
    }
  };

  // delete all values
  let clear = () => {
    _keys = [];
    _data = {};
    serialize();
  };

  let api = {
    key: key,
    getItem: getItem,
    setItem: setItem,
    removeItem: removeItem,
    clear: clear,
  };

  Object.defineProperty(api, 'length', {
    get: () => _keys.length,
  });

  if (window.parent && window.parent.postMessage) {
    serialize = () => {
      let payload = JSON.stringify({ _keys: _keys, _data: _data });
      window.parent.postMessage({
        type: 'MEMORY_STORAGE',
        message: 'SAVE_MEMORY_STORAGE',
        payload: payload,
      }, '*');
    };
  }

  let receiveMessage = event => {
    if ((event.data || {}).type !== 'MEMORY_STORAGE') {
      return;
    }

    switch (event.data.message) {
      case 'LOAD_MEMORY_STORAGE':
        try {
          let loaded = JSON.parse(event.data.payload);
          _keys = loaded._keys || _keys;
          _data = loaded._data || _data;
        } catch (error) {
          // console.warn('failed to load local storage');
        }
        break;
      default:
        break;
    }
  };

  window.addEventListener('message', receiveMessage, false);

  return api;
}

export const MemoryStorage = makeMemoryStorage();
