import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PLAID_PUBLIC_KEY, PLAID_ENV } from '../constants';
import { ButtonPlaid } from '@foyyay/flow-elements';

const SCRIPT_ID = 'plaid-link-script-loader';
const EMPTY_FUNC = () => {};

export default class PlaidLink extends Component {
  static propTypes = {
    linked: PropTypes.bool,
    message: PropTypes.string,
    processing: PropTypes.bool,
    onClickDisconnect: PropTypes.func,
    plaidOnLoad: PropTypes.func,
    plaidOnSuccess: PropTypes.func,
    plaidOnExit: PropTypes.func,
    plaidOnEvent: PropTypes.func,
  };

  static defaultProps = {
    linked: false,
    processing: false,
    onClickDisconnect: EMPTY_FUNC,
    plaidOnLoad: EMPTY_FUNC,
    plaidOnSuccess: EMPTY_FUNC,
    plaidOnExit: EMPTY_FUNC,
    plaidOnEvent: EMPTY_FUNC,
  };

  constructor(props) {
    super(props);

    this.state = {
      plaidLinkLoaded: false,
    };
  }

  componentDidMount() {
    if (window.Plaid || document.getElementById(SCRIPT_ID)) {
      this.setState({ plaidLinkLoaded: true });
    } else {
      this.loadPlaidLinkApi()
        .then(() => {
          this.configurePlaid();
          this.setState({ plaidLinkLoaded: true });
        })
        .catch(err => console.warn('Plaid Link API failed to load:', err));
    }
  }

  loadPlaidLinkApi() {
    return new Promise((resolve, reject) => {
      const src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      var ref = window.document.getElementsByTagName('script')[0];
      var script = window.document.createElement('script');
      script.id = SCRIPT_ID;
      script.onload = resolve;
      script.onerror = reject;
      script.src = src;
      script.async = true;
      ref.parentNode.insertBefore(script, ref);
    });
  }

  configurePlaid = () => {
    window.plaidHandler = window.Plaid.create({
      clientName: 'RebelGive',
      env: PLAID_ENV,
      // Replace with your public_key from the Dashboard
      key: PLAID_PUBLIC_KEY,
      product: ['auth'],
      // Optional, use webhooks to get transaction and error updates
      // webhook: 'https://requestb.in',
      // Optional, specify a language to localize Link
      language: 'en',
      // Optional, specify a user object to enable all Auth features
      // user: {
      //   legalName: 'John Appleseed',
      //   emailAddress: 'jappleseed@yourapp.com',
      // },
      onLoad: this.plaidOnLoad,
      onSuccess: this.plaidOnSuccess,
      onExit: this.plaidOnExit,
      onEvent: this.plaidOnEvent,
    });
  };

  plaidOnLoad = () => {
    // Optional, called when Link loads
    // console.log('Plaid Loaded');
    this.props.plaidOnLoad();
  };

  plaidOnSuccess = (public_token, metadata) => {
    // Send the public_token to your app server.
    // The metadata object contains info about the institution the
    // user selected and the account ID or IDs, if the
    // Select Account view is enabled.
    // $.post('/get_access_token', {
    //   public_token: public_token,
    // });
    // console.log(public_token, metadata);
    this.props.plaidOnSuccess(public_token, metadata);
  };

  plaidOnExit = (err, metadata) => {
    // The user exited the Link flow.
    if (err != null) {
      // The user encountered a Plaid API error prior to exiting.
    }
    // metadata contains information about the institution
    // that the user selected and the most recent API request IDs.
    // Storing this information can be helpful for support.
    // console.log(err, metadata);
    this.props.plaidOnExit(err, metadata);
  };

  plaidOnEvent = (eventName, metadata) => {
    // Optionally capture Link flow events, streamed through
    // this callback as your users connect an Item to Plaid.
    // For example:
    // eventName = "TRANSITION_VIEW"
    // metadata  = {
    //   link_session_id: "123-abc",
    //   mfa_type:        "questions",
    //   timestamp:       "2017-09-14T14:42:19.350Z",
    //   view_name:       "MFA",
    // }
    // console.log(eventName, metadata);
    this.props.plaidOnEvent(eventName, metadata);
  };

  handleOnClick = e => {
    if (this.state.plaidLinkLoaded) {
      window.plaidHandler.open();
    }
  };

  render() {
    let loading = !this.state.plaidLinkLoaded || this.props.processing;
    return (
      <ButtonPlaid
        linked={this.props.linked}
        loading={loading}
        onClick={this.handleOnClick}
        onClickDisconnect={this.props.onClickDisconnect}
      >
        {this.props.message}
      </ButtonPlaid>
    );
  }
}
