import {
  BodyStyle,
  ButtonCircleArrow,
  ButtonOptionPrimary,
  ButtonOptionSecondary,
  Container,
  font,
  H1,
  LabelStep,
  LayoutStepRowSingle,
  media,
  Step,
  StepInput,
  T1,
} from '@foyyay/flow-elements';
import React, { Component, Fragment } from 'react';
import posed from 'react-pose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import styles from './SignIn.module.scss';

const STEP = {
  GETEMAIL: 'getEmail',
  GETPASSWORD: 'getPassword',
  CREATEPASSWORD: 'createPassword',
  SIGNEDIN: 'signedIn',
};

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      asyncProcessing: false,
      asyncError: undefined,
      user: {
        email: '',
        password: '',
        username: '',
      },
      errors: {
        email: undefined,
        password: undefined,
      },
      userExists: false,
    };
  }

  componentDidMount() {
    window.parent.postMessage({ message: 'givingflow-loaded' }, '*');
  }

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeDeep = (key, value) => {
    this.setState(prevState => ({
      [key]: {
        ...prevState[key],
        ...value,
      },
    }));
  };

  handleOnClick = () => {
    window.parent.postMessage({ message: 'givingflow-close-clicked' }, '*');
  };

  checkEmail = e => {
    this.setState({ asyncProcessing: true });

    setTimeout(() => {
      this.setState({ asyncProcessing: false });
      this.props.history.push('/sign-in', { currentStep: STEP.GETPASSWORD });
    }, 300);
  };

  signIn = () => {
    this.setState({ asyncProcessing: true });

    setTimeout(() => {
      this.props.onSignIn();
      this.setState({ asyncProcessing: false });
      this.props.history.replace('/sign-in', { currentStep: STEP.SIGNEDIN });
    }, 300);
  };

  signUp = () => {
    this.setState({ asyncProcessing: true });

    setTimeout(() => {
      this.props.onSignIn();
      this.setState({ asyncProcessing: false });
      this.props.history.replace('/sign-in', { currentStep: STEP.SIGNEDIN });
    }, 300);
  };

  signOut = () => {
    this.props.onSignOut();
    this.props.history.replace('/flows/1');
  };

  render() {
    let { asyncProcessing, user, userExists, errors } = this.state;
    let { location } = this.props;
    let currentStep = STEP.GETEMAIL;

    if (location.state) {
      currentStep = location.state.currentStep;
    }

    if (currentStep === STEP.GETPASSWORD && user.email === '') {
      this.props.history.replace('/sign-in', { currentStep: STEP.GETEMAIL });
    }

    let signedIn = currentStep === STEP.SIGNEDIN;
    return (
      <Fragment>
        <BodyStyle />
        <FlowWrapper>
          <Header isAuthenticated={signedIn || this.props.isAuthenticated} />
          <FlowBody>
            <ScrollContainer>
              <FlowContent>
                <Flow>
                  <FlowContainer>
                    <div className={styles.positionContainer}>
                      <Container>
                        <Fragment>
                          {currentStep === STEP.GETEMAIL && (
                            <Fragment>
                              <SectionText title="Hi there,">
                                Just enter your email and we’ll help you get to the right place. If
                                you already have an account, try to use that same email.
                              </SectionText>
                              <div className={styles.sectionEmail}>
                                <Step>
                                  <LabelStep absolute error={errors.email}>
                                    WHAT'S YOUR EMAIL?
                                  </LabelStep>
                                  <LayoutStepRowSingle>
                                    <StepInput
                                      type="email"
                                      placeholder="email@example.com"
                                      spellCheck="false"
                                      value={user.email}
                                      onChange={value =>
                                        this.onChangeDeep('user', { email: value })
                                      }
                                      onEnter={this.checkEmail}
                                      error={errors.email}
                                    />
                                    <ButtonCircleArrow
                                      onClick={this.checkEmail}
                                      loading={asyncProcessing}
                                    />
                                  </LayoutStepRowSingle>
                                </Step>
                              </div>
                              <SignInBackTextLink />
                            </Fragment>
                          )}

                          {currentStep === STEP.GETPASSWORD && userExists && (
                            <Fragment>
                              <SectionText title="Welcome Back!">
                                Now, just enter the password for your RebelGive account. Can’t
                                remember it? No worries, we can send a reset email.
                              </SectionText>
                              <div className={styles.sectionEmail}>
                                <Step>
                                  <LabelStep absolute error={errors.password}>
                                    Enter Your Password
                                  </LabelStep>
                                  <LayoutStepRowSingle>
                                    <StepInput
                                      type="password"
                                      placeholder="password"
                                      value={user.password}
                                      onChange={value =>
                                        this.onChangeDeep('user', { password: value })
                                      }
                                      onEnter={this.signIn}
                                      isValid={!errors.password}
                                      error={errors.password}
                                    />
                                    <ButtonCircleArrow
                                      onClick={this.signIn}
                                      loading={asyncProcessing}
                                    />
                                  </LayoutStepRowSingle>
                                </Step>
                              </div>
                              <SignInBackTextLink />
                            </Fragment>
                          )}

                          {currentStep === STEP.SIGNEDIN && userExists && (
                            <Fragment>
                              <SectionText title="Hi there,">
                                Welcome back! Just choose what you want to do, and we’ll send you
                                there so you can get started right away.
                              </SectionText>
                              <MyAccountButtons />
                              <div className={styles.sectionReturn}>
                                <T1>
                                  <span>If you're on a shared device, you can</span>{' '}
                                  <span
                                    className={styles.textLink}
                                    role="button"
                                    onClick={this.signOut}
                                  >
                                    sign out
                                  </span>
                                </T1>
                              </div>
                            </Fragment>
                          )}

                          {currentStep === STEP.GETPASSWORD && !userExists && (
                            <Fragment>
                              <SectionText title="Create your account">
                                Simply enter a password for your new RebelGive account, and then
                                we’ll take you to the Control Center to get things set up.
                              </SectionText>
                              <div className={styles.sectionEmail}>
                                <Step>
                                  <LabelStep absolute>Create A Password</LabelStep>
                                  <LayoutStepRowSingle>
                                    <StepInput
                                      type="password"
                                      placeholder="password"
                                      value={user.password}
                                      onChange={value =>
                                        this.onChangeDeep('user', { password: value })
                                      }
                                      onEnter={this.signUp}
                                    />
                                    <ButtonCircleArrow
                                      onClick={this.signUp}
                                      loading={asyncProcessing}
                                    />
                                  </LayoutStepRowSingle>
                                </Step>
                              </div>
                            </Fragment>
                          )}

                          {currentStep === STEP.SIGNEDIN && !userExists && (
                            <Fragment>
                              <SectionText title="Welcome!">
                                You can start giving or check out your account. Just choose what you
                                want to do, and we’ll send you there so you can get started right
                                away.
                              </SectionText>
                              <MyAccountButtons />
                              <div className={styles.sectionReturn}>
                                <T1>
                                  <span>If you're on a shared device, you can</span>{' '}
                                  <span
                                    className={styles.textLink}
                                    role="button"
                                    onClick={this.signOut}
                                  >
                                    sign out
                                  </span>
                                </T1>
                              </div>
                            </Fragment>
                          )}
                        </Fragment>
                      </Container>
                    </div>
                  </FlowContainer>
                </Flow>
              </FlowContent>
            </ScrollContainer>
          </FlowBody>
          <FlowFooter>
            <Footer />
          </FlowFooter>
        </FlowWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(SignIn);

const MyAccountButtons = props => {
  return (
    <div className={styles.sectionButtons}>
      <Link to="/flows/1">
        <ButtonOptionPrimary>Start Giving</ButtonOptionPrimary>
      </Link>
      <a target="_blank" rel="noopener noreferrer">
        <ButtonOptionSecondary>Go to my account</ButtonOptionSecondary>
      </a>
    </div>
  );
};

const SectionText = props => {
  return (
    <div className={styles.sectionText}>
      <H1>{props.title}</H1>
      <T1>{props.children}</T1>
    </div>
  );
};

const BackTextLinkContainer = styled.div`
  text-align: center;
  margin-top: 6rem;
`;

const BackTextLink = styled(Link)`
  ${font(16, 'Book', -0.3, 16)}
  opacity: 0.5;
  text-align: center;
  margin: 0 auto;
  transition: 250ms opacity ease-out;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;

const UnderlineText = styled.span`
  text-decoration: underline;
`;

const SignInBackTextLink = props => (
  <BackTextLinkContainer>
    <BackTextLink to="/flows/1">
      Nevermind, <UnderlineText>don't sign in</UnderlineText>
    </BackTextLink>
  </BackTextLinkContainer>
);

const poseListContainerProps = {
  // enter: { staggerChildren: 50 },
  // exit: { staggerChildren: 20, staggerDirection: -1 }
};

const poseListItemProps = {
  // enter: { y: 0, opacity: 1 },
  // exit: { y: 50, opacity: 0 }
};

const FlowWrapper = styled.div`
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

const FlowBody = styled.div`
  position: relative;
  flex: 1;
`;

const ScrollContainer = styled.div`
  z-index: 2147483002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const FlowContent = styled(posed.div(poseListContainerProps))`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
`;

const Flow = styled(posed.div(poseListItemProps))`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 20rem;
  flex: 1 0 auto;
`;

const HORIZ_PAD = '1.2rem';

const FlowContainer = styled.div`
  width: 100%;
  max-width: calc(49rem + ${HORIZ_PAD} * 2);
  padding-left: ${HORIZ_PAD};
  padding-right: ${HORIZ_PAD};
  min-height: 32vh;

  ${media.tabletLandscapeAndUp`
    max-width: calc(58rem + ${HORIZ_PAD} * 2);
    min-height: 34vh;
  `}
`;

const FlowFooter = styled.div`
  z-index: 2147483004;
  top: auto;
  bottom: auto;
`;
