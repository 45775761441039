import React from 'react';
import { Route, withRouter } from 'react-router-dom';

class RouteWithAuthComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
    // this.authenticate();
    // this.unlisten = this.props.history.listen(() => this.authenticate());
  }

  componentWillUnmount() {
    // this.unlisten();
    window.removeEventListener('message', this.receiveMessage, false);
  }

  receiveMessage = event => {
    if (((event || {}).data || {}).message === 'LOAD_MEMORY_STORAGE') {
      // this.authenticate();
    }
  };

  handleSignIn = () => {
    this.setState({isAuthenticated: true});
  };

  handleSignOut = () => {
    this.setState({isAuthenticated: false});
  };

  // async authenticate() {
  //   try {
  //     await Auth.currentAuthenticatedUser();
  //     this.setState({ loaded: true, isAuthenticated: true });
  //   } catch (error) {
  //     this.setState({ loaded: true, isAuthenticated: false });
  //   }
  // }

  render() {
    // console.log('isAuthenticated', this.state.isAuthenticated);
    const { component: Component, render: Render, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;
    const newProps = {
      ...this.props,
      isAuthenticated: isAuthenticated,
    };
    // if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={props => {
          if (Component !== undefined) {
            return <Component {...newProps} onSignIn={this.handleSignIn} onSignOut={this.handleSignOut} />;
          } else if (Render !== undefined) {
            return Render(newProps);
          } else {
            return null;
          }
        }}
      />
    );
  }
}

export default withRouter(RouteWithAuthComponent);
