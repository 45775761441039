import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonOptionPrimary } from '@foyyay/flow-elements';

export default class Select extends Component {
  static propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
  };

  handleClick = index => {
    let value = this.props.options[index];
    this.props.onChange(value);
  };

  render() {
    return (
      <div>
        {this.props.options.map((option, index) => (
          <ButtonOptionPrimary key={option} onClick={e => this.handleClick(index)}>
            {option}
          </ButtonOptionPrimary>
        ))}
      </div>
    );
  }
}
