import { BodyStyle, ButtonCircleArrow, ButtonOptionPrimary, ButtonOptionSecondary, Container, H1, LabelStep, LayoutStepRowSingle, media, Step, StepInput, T1 } from '@foyyay/flow-elements';
import React, { Component, Fragment } from 'react';
import posed from 'react-pose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { GIVINGFLOW_LS_STATE_KEY } from '../../constants';
import { MemoryStorage } from '../../storage/memory';
import styles from './ThankYou.module.scss';

const STEP = {
  GETEMAIL: 'getEmail',
  GETPASSWORD: 'getPassword',
  CREATEPASSWORD: 'createPassword',
  SIGNEDIN: 'signedIn',
};

class ThankYou extends Component {
  constructor(props) {
    super(props);

    this.state = {
      asyncProcessing: false,
      asyncError: undefined,
      user: {
        email: '',
        password: '',
        username: '',
      },
      errors: {
        email: undefined,
        password: undefined,
      },
      userExists: false,
    };
  }

  componentDidMount() {
    window.parent.postMessage({ message: 'givingflow-loaded' }, '*');
    MemoryStorage.removeItem(GIVINGFLOW_LS_STATE_KEY);
  }

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeDeep = (key, value) => {
    this.setState(prevState => ({
      [key]: {
        ...prevState[key],
        ...value,
      },
    }));
  };

  handleOnClick = () => {
    window.parent.postMessage({ message: 'givingflow-close-clicked' }, '*');
  };

  checkEmail = e => {
    this.setState({ asyncProcessing: true });

    setTimeout(() => {
      this.setState({ asyncProcessing: false });
      this.props.history.push('/thank-you', { currentStep: STEP.GETPASSWORD });
    }, 300);
  };

  signIn = () => {
    this.setState({ asyncProcessing: true });

    setTimeout(() => {
      this.props.onSignIn();
      this.setState({ asyncProcessing: false });
      this.props.history.replace('/thank-you', { currentStep: STEP.SIGNEDIN });
    }, 300);
  };

  signUp = () => {
    this.setState({ asyncProcessing: true });

    setTimeout(() => {
      this.props.onSignIn();
      this.setState({ asyncProcessing: false });
      this.props.history.replace('/thank-you', { currentStep: STEP.SIGNEDIN });
    }, 300);
  };

  signOut = () => {
    this.props.onSignOut();
    this.props.history.replace('/flows/1');
  };

  render() {
    let { isAuthenticated, location } = this.props;
    let { asyncProcessing, user, userExists, errors } = this.state;
    let currentStep = STEP.GETEMAIL;

    if (location.state) {
      currentStep = location.state.currentStep;
    }

    let signedIn = currentStep === STEP.SIGNEDIN;
    return (
      <Fragment>
        <BodyStyle />
        <FlowWrapper>
          <Header isAuthenticated={signedIn || this.props.isAuthenticated} />
          <FlowBody>
            <ScrollContainer>
              <FlowContent>
                <Flow>
                  <FlowContainer>
                    <div className={styles.positionContainer}>
                      <Container>
                        {isAuthenticated && (
                          <Fragment>
                            <SectionText title="Thank You!">
                              We’re so grateful for you and your generosity towards our family, as
                              we preach the Gospel here in Cambria.
                            </SectionText>
                            <AccountButtons />
                            <div className={styles.sectionReturn}>
                              <T1>
                                <span>If you're on a shared device, you can</span>{' '}
                                <span
                                  className={styles.textLink}
                                  role="button"
                                  onClick={this.handleSignOutClick}
                                >
                                  sign out
                                </span>
                              </T1>
                            </div>
                          </Fragment>
                        )}

                        {!isAuthenticated && (
                          <Fragment>
                            {currentStep === STEP.GETEMAIL && (
                              <Fragment>
                                <SectionText title="Thank You!">
                                  We’re so grateful for you and your generosity towards our family,
                                  as we preach the Gospel here in Cambria.
                                </SectionText>
                                <div className={styles.sectionEmail}>
                                  <Step>
                                    <LabelStep absolute error={errors.email}>
                                      ENTER YOUR EMAIL FOR A RECEIPT
                                    </LabelStep>
                                    <LayoutStepRowSingle>
                                      <StepInput
                                        type="email"
                                        placeholder="email@example.com"
                                        spellCheck="false"
                                        value={user.email}
                                        onChange={value =>
                                          this.onChangeDeep('user', { email: value })
                                        }
                                        onEnter={this.checkEmail}
                                        error={errors.email}
                                      />
                                      <ButtonCircleArrow
                                        onClick={this.checkEmail}
                                        loading={asyncProcessing}
                                      />
                                    </LayoutStepRowSingle>
                                  </Step>
                                </div>
                              </Fragment>
                            )}

                            {currentStep === STEP.GETPASSWORD && userExists && (
                              <Fragment>
                                <SectionText title="Welcome Back!">
                                  Simply enter your password to save this gift to your account.
                                  Can’t remember your password? Request a reset email
                                </SectionText>
                                <div className={styles.sectionEmail}>
                                  <Step>
                                    <LabelStep absolute error={errors.password}>
                                      Enter Your Password
                                    </LabelStep>
                                    <LayoutStepRowSingle>
                                      <StepInput
                                        type="password"
                                        placeholder="password"
                                        value={user.password}
                                        onChange={value =>
                                          this.onChangeDeep('user', { password: value })
                                        }
                                        onEnter={this.signIn}
                                        isValid={!errors.password}
                                        error={errors.password}
                                      />
                                      <ButtonCircleArrow
                                        onClick={this.signIn}
                                        loading={asyncProcessing}
                                      />
                                    </LayoutStepRowSingle>
                                  </Step>
                                </div>
                              </Fragment>
                            )}

                            {currentStep === STEP.GETPASSWORD && !userExists && (
                              <Fragment>
                                <SectionText title="Save your account?">
                                  Simply create a password to access your giving history, and it
                                  will be even easier and faster to give next time. Sound good?
                                </SectionText>
                                <div className={styles.sectionEmail}>
                                  <Step>
                                    <LabelStep absolute>Create A Password</LabelStep>
                                    <LayoutStepRowSingle>
                                      <StepInput
                                        type="password"
                                        placeholder="password"
                                        value={user.password}
                                        onChange={value =>
                                          this.onChangeDeep('user', { password: value })
                                        }
                                        onEnter={this.signUp}
                                      />
                                      <ButtonCircleArrow
                                        onClick={this.signUp}
                                        loading={asyncProcessing}
                                      />
                                    </LayoutStepRowSingle>
                                  </Step>
                                </div>
                              </Fragment>
                            )}

                            {currentStep === STEP.SIGNEDIN && userExists && (
                              <Fragment>
                                <SectionText title="Hi there,">
                                  Your gift and method has been saved in your account. Now you can
                                  easily view your account or give again anytime.
                                </SectionText>
                                <AccountButtons />
                                <div className={styles.sectionReturn}>
                                  <T1>
                                    <span>If you're on a shared device, you can</span>{' '}
                                    <span
                                      className={styles.textLink}
                                      role="button"
                                      onClick={this.signOut}
                                    >
                                      sign out
                                    </span>
                                  </T1>
                                </div>
                              </Fragment>
                            )}

                            {currentStep === STEP.SIGNEDIN && !userExists && (
                              <Fragment>
                                <SectionText title="Welcome!">
                                  Your gift and method has been saved in your account. Now you can
                                  easily view your account or give again anytime.
                                </SectionText>
                                <AccountButtons />
                                <div className={styles.sectionReturn}>
                                  <T1>
                                    <span>If you're on a shared device, you can</span>{' '}
                                    <span
                                      className={styles.textLink}
                                      role="button"
                                      onClick={this.signOut}
                                    >
                                      sign out
                                    </span>
                                  </T1>
                                </div>
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      </Container>
                    </div>
                  </FlowContainer>
                </Flow>
              </FlowContent>
            </ScrollContainer>
          </FlowBody>
          <FlowFooter>
            <Footer />
          </FlowFooter>
        </FlowWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ThankYou);

const SectionText = props => {
  return (
    <div className={styles.sectionText}>
      <H1>{props.title}</H1>
      <T1>{props.children}</T1>
    </div>
  );
};

const AccountButtons = props => {
  return (
    <div className={styles.sectionButtons}>
      <a target="_blank" rel="noopener noreferrer">
        <ButtonOptionPrimary>View My Giving</ButtonOptionPrimary>
      </a>
      <Link to="/flows/1">
        <ButtonOptionSecondary>Or, give again</ButtonOptionSecondary>
      </Link>
    </div>
  );
};

const poseListContainerProps = {
  // enter: { staggerChildren: 50 },
  // exit: { staggerChildren: 20, staggerDirection: -1 }
};

const poseListItemProps = {
  // enter: { y: 0, opacity: 1 },
  // exit: { y: 50, opacity: 0 }
};

const FlowWrapper = styled.div`
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

const FlowBody = styled.div`
  position: relative;
  flex: 1;
`;

const ScrollContainer = styled.div`
  z-index: 2147483002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const FlowContent = styled(posed.div(poseListContainerProps))`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
`;

const Flow = styled(posed.div(poseListItemProps))`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 20rem;
  flex: 1 0 auto;
`;

const HORIZ_PAD = '1.2rem';

const FlowContainer = styled.div`
  width: 100%;
  max-width: calc(49rem + ${HORIZ_PAD} * 2);
  padding-left: ${HORIZ_PAD};
  padding-right: ${HORIZ_PAD};
  min-height: 32vh;

  ${media.tabletLandscapeAndUp`
    max-width: calc(68rem + ${HORIZ_PAD} * 2);
    min-height: 34vh;
  `}
`;

const FlowFooter = styled.div`
  z-index: 2147483004;
  top: auto;
  bottom: auto;
`;
