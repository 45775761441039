import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Theme, createTheme, HtmlStyle } from '@foyyay/flow-elements';
import { Routes } from './routes/Routes';
import { GIVING_FLOW_COLOR_MODE_DARK } from './constants';

import './css/normalize.css';
import './css/fonts.css';
import './css/index.css';

const Pallette = {
  Dark1: 'transparent',
  Dark2: 'transparent',
  Light: 'transparent',
  Vibrant1: 'transparent',
  Vibrant2: 'transparent',
};

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: createTheme(Pallette, GIVING_FLOW_COLOR_MODE_DARK),
      themeSet: false,
    };
  }

  setTheme = theme => {
    if (this.state.themeSet) {
      return;
    }
    this.setState({ theme: theme, themeSet: true });
  };

  render() {
    return (
      <Theme.Provider value={this.state.theme}>
        <HtmlStyle />
        <Router>
          <Routes setTheme={this.setTheme} />
        </Router>
      </Theme.Provider>
    );
  }
}
