import React from 'react';
import { H3, T3, media } from '@foyyay/flow-elements';
import styled from 'styled-components';

const SummaryContainer = styled.div`
  box-sizing: border-box;
  margin-top: 2.2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 34rem;
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  width: 100%;

  ${media.tabletLandscapeAndUp`
    padding-left: 3.6rem;
    padding-right: 3.6rem;
    margin-top: 3.6rem;
    max-width: 48rem;
  `};
`;

const Total = styled(H3)`
  margin: 0;
`;

const Description = styled(T3)`
  margin: 0.2em 0 0 0;
`;

const LearnMore = styled.a`
  white-space: nowrap;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 250ms ease-out;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;

export const Summary = React.forwardRef((props, ref) => (
  <SummaryContainer ref={ref}>
    <Total>For a total of {props.total}</Total>
    <Description>
      <LearnMore onClick={props.onLearnMoreClick}>
        Includes {props.fee} convenience fee &mdash;&nbsp;Learn More
      </LearnMore>
    </Description>
  </SummaryContainer>
));
