import {
  BodyStyle,
  ButtonCircleArrow,
  ButtonCircleX,
  ButtonOptionPrimary,
  ButtonOptionSecondary,
  ButtonPrimary,
  createPallette,
  createTheme,
  CreditCardDetails,
  H2,
  InputCard,
  InputCurrency,
  L1,
  LabelStep,
  LayoutStepRowSingle,
  Logo,
  media,
  Prompt,
  Step,
  T1,
} from '@foyyay/flow-elements';
import React, { Component, Fragment } from 'react';
import posed from 'react-pose';
import scrollIntoView from 'scroll-into-view';
import styled from 'styled-components';
import Footer from '../../components/Footer';
// import styles from './GivingFlow.module.scss';
import Header from '../../components/Header';
import PlaidLink from '../../components/PlaidLink';
import Select from '../../components/Select';
import { Summary } from '../../components/Summary';
import { GIVINGFLOW_LS_STATE_KEY, GIVING_FLOW_COLOR_MODE_LIGHT } from '../../constants';
import { MemoryStorage } from '../../storage/memory';

const STEP = {
  GET_AMOUNT: 'get_amount',
  GET_FUND: 'get_fund',
  GET_FREQUENCY: 'get_frequency',
  GET_PAYMENT_TYPE: 'get_payment_type',
  GET_PAYMENT_INFO: 'get_payment_info',
  GET_PLAID: 'get_plaid',
  GET_BANK_ACCOUNT: 'get_bank_account',
  GET_CREDIT_CARD: 'get_credit_card',
  GET_SUMMARY: 'get_summary',
};

const PERSISTANT_KEYS = [
  'currentStep',
  'completedSteps',
  'amount',
  'fund',
  'frequency',
  'paymentType',
  'card_token',
  'token_card_type',
  'token_card_last_four',
  'plaid_token',
  'plaid_account_number',
  'learnMoreShown',
  'progress',
];

const STEPS = [
  STEP.GET_AMOUNT,
  STEP.GET_FUND,
  STEP.GET_FREQUENCY,
  STEP.GET_PAYMENT_TYPE,
  STEP.GET_PAYMENT_INFO,
];

class GivingFlow extends Component {
  // funds = ['General', 'Anthem Thousand Oaks', 'Anthem Camarillo', 'Anthem Ventura'];
  frequency = ['Just this once', 'Every week', 'Every two weeks', 'Every month'];
  paymentTypes = ['Bank Account', 'Credit Card'];
  feePercentage = 0.019;

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      currentStep: STEP.GET_AMOUNT,
      completedSteps: [],
      amount: '',
      fund: '',
      frequency: '',
      paymentType: '',
      card_account_number: '',
      card_cvv: '',
      card_expire_month: '',
      card_expire_year: '',
      card_type: '',
      card_token: '',
      token_card_type: '',
      token_card_last_four: '',
      plaid_token: '',
      plaid_account_number: '',
      learnMoreShown: false,
      progress: 0,
      Flow: {},
      Designations: [],
      SourceTypes: [],
      MerchantId: undefined,
    };

    for (var step of STEPS) {
      this[step] = React.createRef();
    }
    this[STEP.GET_SUMMARY] = React.createRef();
    this.plaidLink = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
    window.parent.postMessage({ message: 'givingflow-loaded' }, '*');
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);
  }

  receiveMessage = (event) => {
    switch (event.data.message) {
      case 'receive-params':
        this.receiveParams(event.data.payload);
        break;

      case 'LOAD_MEMORY_STORAGE':
        try {
          let state = JSON.parse(MemoryStorage.getItem(GIVINGFLOW_LS_STATE_KEY));
          this.setState({ ...state });
        } catch (error) {}
        break;

      default:
        break;
    }
  };

  receiveParams = (payload) => {
    if (this.state.loaded) {
      return;
    }

    let { Flow, Designations, SourceTypes, MerchantId } = payload;
    let theme = createTheme(createPallette(Flow.color_flow), Flow.color_mode);
    this.props.setTheme(theme);
    this.setState({ loaded: true, ...payload }, () => {
      setTimeout(() => {
        this.goToNextStep();
      }, 250);
    });
  };

  persistState = (stateSetter, callback) => {
    this.setState(stateSetter, () => {
      this.setPersistentState(this.state);
      if (callback) {
        callback();
      }
    });
  };

  setPersistentState = (state) => {
    let toSave = PERSISTANT_KEYS.reduce((acc, next) => {
      return {
        ...acc,
        [next]: state[next],
      };
    }, {});

    MemoryStorage.setItem(GIVINGFLOW_LS_STATE_KEY, JSON.stringify(toSave));
  };

  scrollTo = (element) => {
    scrollIntoView(element, {
      time: 500,
    });
  };

  goToNextStep = () => {
    // try to reach the final step
    let nextStep = STEP.GET_SUMMARY;
    // but if a step is not completed -> set that as the current step
    for (var step of STEPS) {
      if (!this.state.completedSteps.includes(step)) {
        nextStep = step;
        break;
      }
    }
    this.persistState(
      (state) => {
        return { currentStep: nextStep, progress: state.completedSteps.length };
      },
      () => {
        (() => this.scrollTo(this[nextStep].current))();
      }
    );
  };

  goToStep = (step) => {
    this.persistState(
      (state) => {
        // let completedSteps = state.completedSteps.filter(a => a !== step);
        return {
          currentStep: step,
          // completedSteps: completedSteps,
          // progress: completedSteps.length,
        };
      },
      () => this.scrollTo(this[step].current)
    );
  };

  handleStepClick = (step) => {
    // if step is not currently active step -> set as currently active step
    if (this.state.currentStep !== step) {
      this.persistState(
        (state) => {
          return { currentStep: step, progress: state.completedSteps.length - 1 };
        },
        () => this.scrollTo(this[step].current)
      );
    }
  };

  handleStepCompleteClick = (step, nextStep = null, stateToChange = {}) => {
    this.persistState(
      (state) => {
        return {
          completedSteps: [step, ...state.completedSteps.filter((a) => a !== step)],
          progress: state.progress + 1,
          ...stateToChange,
        };
      },
      nextStep ? () => this.goToStep(nextStep) : () => this.goToNextStep()
    );
  };

  handleGiveNowClick = () => {
    // console.log('give now clicked');
    this.props.history.push('/thank-you');
  };

  validAmount = () => {
    if (this.state.amount !== '') {
      return true;
    }
    return false;
  };

  handleAmountChange = (value) => {
    this.persistState((state) => {
      return { amount: value };
    });
  };

  handleFundsChange = (value) => {
    let stateToChange = {
      fund: value,
    };
    this.handleStepCompleteClick(STEP.GET_FUND, null, stateToChange);
  };

  handleFrequencyChange = (value) => {
    let stateToChange = {
      frequency: value,
    };
    this.handleStepCompleteClick(STEP.GET_FREQUENCY, null, stateToChange);
  };

  handlePaymentChange = (value) => {
    let stateToChange = {
      paymentType: value,
    };
    this.handleStepCompleteClick(STEP.GET_PAYMENT_TYPE, STEP.GET_PAYMENT_INFO, stateToChange);
  };

  handleCardSubmit = () => {
    // let maskedCardNumber = this.state.card_account_number
    //   ? 'X'.repeat(this.state.card_account_number.length - 4) + this.state.card_account_number.slice(-4)
    //   : '';
    let stateToChange = {
      card_token: 'tokengoeshere',
      token_card_last_four: this.state.card_account_number.slice(-4),
      token_card_type: this.state.card_type,
    };
    this.handleStepCompleteClick(STEP.GET_PAYMENT_INFO, null, stateToChange);
  };

  handleClearCreditCardClick = () => {
    let completedSteps = this.state.completedSteps.filter((a) => a !== STEP.GET_PAYMENT_INFO);
    this.persistState((state) => {
      return {
        card_account_number: '',
        card_expire_month: '',
        card_expire_year: '',
        card_cvv: '',
        card_type: '',
        card_token: '',
        token_card_last_four: '',
        token_card_type: '',
        completedSteps: completedSteps,
      };
    });
  };

  handleCardChange = (value) => {
    this.setState({
      ...value,
    });
  };

  handlePlaidOnSuccess = (token, metadata) => {
    // console.log(metadata);
    let accountNumber = metadata.accounts[0].mask;
    this.persistState((state) => {
      return {
        plaid_token: token,
        plaid_account_number: accountNumber,
      };
    });
  };

  handlePlaidDisconnect = () => {
    this.persistState((state) => {
      return {
        plaid_token: '',
        plaid_account_number: '',
      };
    });
  };

  handleLearnMoreClick = (e) => {
    this.persistState((state) => {
      return { learnMoreShown: true };
    });
  };

  handleLearnMoreCloseClick = (e) => {
    this.persistState((state) => {
      return { learnMoreShown: false };
    });
  };

  handleUseBankAccountClick = (e) => {
    const step = STEP.GET_PAYMENT_INFO;
    const stateToChange = {
      paymentType: 'Bank Account',
    };
    this.persistState(
      (state) => {
        return {
          learnMoreShown: false,
          currentStep: step,
          completedSteps: [...state.completedSteps.filter((a) => a !== step)],
          progress: state.progress - 1,
          ...stateToChange,
        };
      },
      () => {
        (() => this.scrollTo(this[step].current))();
      }
    );
  };

  formatNumberAsUsd(value) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
      .format(value)
      .replace(/\D00$/, '');
  }

  calculateTotal() {
    let amount = this.state.amount < 1 ? 1 : this.state.amount;

    if (amount === '') {
      return 0;
    }

    let dollars = parseFloat(amount);
    let fee = parseFloat(this.calculateFee());
    let total = dollars + fee;

    return total;
  }

  calculateFee() {
    if (this.state.paymentType === 'Bank Account') {
      return 0.25;
    }

    let amount = this.state.amount < 1 ? 1 : this.state.amount;

    if (amount === '') {
      return 0;
    }

    amount = parseFloat(amount);

    let dollars = amount * this.feePercentage;
    let cents = dollars * 100;
    let roundedCents = Math.round(cents);
    let roundedFee = parseFloat(roundedCents / 100).toFixed(2);

    return roundedFee;
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }

    const total = this.formatNumberAsUsd(this.calculateTotal());
    const fee = this.formatNumberAsUsd(this.calculateFee());

    const isAmountStepShown =
      this.state.currentStep === STEP.GET_AMOUNT ||
      this.state.completedSteps.includes(STEP.GET_AMOUNT);
    const isFundStepShown =
      this.state.currentStep === STEP.GET_FUND || this.state.completedSteps.includes(STEP.GET_FUND);
    const isFrequencyStepShown =
      this.state.currentStep === STEP.GET_FREQUENCY ||
      this.state.completedSteps.includes(STEP.GET_FREQUENCY);
    const isPaymentTypeStepShown =
      this.state.currentStep === STEP.GET_PAYMENT_TYPE ||
      this.state.completedSteps.includes(STEP.GET_PAYMENT_TYPE);
    const isPaymentInfoStepShown =
      this.state.currentStep === STEP.GET_PAYMENT_INFO ||
      this.state.completedSteps.includes(STEP.GET_PAYMENT_INFO);
    const isSummaryShown = this.state.currentStep === STEP.GET_SUMMARY;

    const isValidCreditCard =
      this.state.card_account_number.length >= 15 &&
      this.state.card_expire_month.length === 2 &&
      this.state.card_expire_year.length === 2 &&
      this.state.card_cvv.length >= 3;
    // Possible card type values:
    // [amex, mastercard, visa, diners, discover, jcb, dankort, instapayment, uatp, mir, unionPay]
    const cardTypeMessage = `${
      this.state.card_type !== 'unknown'
        ? 'a  ' +
          this.state.token_card_type.charAt(0).toUpperCase() +
          this.state.token_card_type.slice(1)
        : ''
    } ending in ${this.state.token_card_last_four}`;
    const plaidAccountMessage = `ending in ${this.state.plaid_account_number}`;

    let logoSrc = '/logo-light.png';
    if (this.state.Flow.color_mode === GIVING_FLOW_COLOR_MODE_LIGHT) {
      logoSrc = '/logo-dark.png';
    }

    return (
      <Fragment>
        <BodyStyle />
        <PosedFlowWrapper pose={this.state.learnMoreShown ? 'hide' : 'init'}>
          <Header isAuthenticated={this.props.isAuthenticated} />
          <FlowBody>
            <ScrollContainer>
              <FlowContent>
                <FlowLogo>
                  <Logo>
                    <img src={logoSrc} alt="Church Logo" />
                  </Logo>
                </FlowLogo>
                <Flow>
                  <FlowContainer>
                    {this.state.currentStep === STEP.GET_AMOUNT && (
                      <L1 style={{ textAlign: 'center' }}>How much would you like to give?</L1>
                    )}
                    {isAmountStepShown && (
                      <Step
                        collapsed={this.state.currentStep !== STEP.GET_AMOUNT}
                        message={`I'd like to give ${this.formatNumberAsUsd(
                          this.state.amount < 1 ? '1' : this.state.amount
                        )}`}
                        onClick={() => this.handleStepClick(STEP.GET_AMOUNT)}
                        ref={this[STEP.GET_AMOUNT]}
                        allStepsCompleted={isSummaryShown}
                      >
                        <LayoutStepRowSingle>
                          <InputCurrency
                            value={this.state.amount}
                            onChange={this.handleAmountChange}
                            onEnter={() => this.handleStepCompleteClick(STEP.GET_AMOUNT)}
                          />
                          <div>
                            {this.validAmount() && (
                              <ButtonCircleArrow
                                onClick={() => this.handleStepCompleteClick(STEP.GET_AMOUNT)}
                              />
                            )}
                          </div>
                        </LayoutStepRowSingle>
                      </Step>
                    )}

                    {isFundStepShown && (
                      <Step
                        collapsed={this.state.currentStep !== STEP.GET_FUND}
                        message={`for ${this.state.fund}`}
                        onClick={() => this.handleStepClick(STEP.GET_FUND)}
                        ref={this[STEP.GET_FUND]}
                        allStepsCompleted={isSummaryShown}
                      >
                        <LabelStep>What fund should this be for?</LabelStep>
                        <Select
                          options={this.state.Designations.reduce(
                            (acc, current) => [...acc, current.name],
                            []
                          )}
                          onChange={this.handleFundsChange}
                        />
                      </Step>
                    )}

                    {isFrequencyStepShown && (
                      <Step
                        collapsed={this.state.currentStep !== STEP.GET_FREQUENCY}
                        message={this.state.frequency}
                        onClick={() => this.handleStepClick(STEP.GET_FREQUENCY)}
                        ref={this[STEP.GET_FREQUENCY]}
                        allStepsCompleted={isSummaryShown}
                      >
                        <LabelStep>How Often?</LabelStep>
                        <Select options={this.frequency} onChange={this.handleFrequencyChange} />
                      </Step>
                    )}

                    {isPaymentTypeStepShown && (
                      <Step
                        collapsed={this.state.currentStep !== STEP.GET_PAYMENT_TYPE}
                        message={`Using my ${this.state.paymentType}`}
                        onClick={() => this.handleStepClick(STEP.GET_PAYMENT_TYPE)}
                        ref={this[STEP.GET_PAYMENT_TYPE]}
                        allStepsCompleted={isSummaryShown}
                      >
                        <LabelStep>How do you want to give?</LabelStep>
                        <Select options={this.paymentTypes} onChange={this.handlePaymentChange} />
                      </Step>
                    )}

                    {isPaymentInfoStepShown && (
                      <Fragment>
                        {this.state.paymentType === 'Bank Account' && (
                          <Step
                            collapsed={this.state.currentStep !== STEP.GET_PAYMENT_INFO}
                            message={plaidAccountMessage}
                            onClick={() => this.handleStepClick(STEP.GET_PAYMENT_INFO)}
                            ref={this[STEP.GET_PAYMENT_INFO]}
                            allStepsCompleted={isSummaryShown}
                          >
                            <LabelStep>Ok, Let's Link Your Account</LabelStep>
                            <PlaidLink
                              plaidOnSuccess={this.handlePlaidOnSuccess}
                              onClickDisconnect={this.handlePlaidDisconnect}
                              linked={this.state.plaid_token !== ''}
                              message={`Bank account ${plaidAccountMessage}`}
                              ref={this.plaidLink}
                            />
                            {this.state.plaid_token && (
                              <ButtonPrimary
                                onClick={() => this.handleStepCompleteClick(STEP.GET_PAYMENT_INFO)}
                              >
                                Continue
                              </ButtonPrimary>
                            )}
                          </Step>
                        )}

                        {this.state.paymentType === 'Credit Card' && (
                          <Step
                            collapsed={this.state.currentStep !== STEP.GET_PAYMENT_INFO}
                            message={cardTypeMessage}
                            onClick={() => this.handleStepClick(STEP.GET_PAYMENT_INFO)}
                            ref={this[STEP.GET_PAYMENT_INFO]}
                            allStepsCompleted={isSummaryShown}
                          >
                            {!this.state.card_token ? (
                              <Fragment>
                                <LabelStep>Okay, Let's use a credit card</LabelStep>
                                <InputCard
                                  card_account_number={this.state.card_account_number}
                                  card_cvv={this.state.card_cvv}
                                  card_expire_month={this.state.card_expire_month}
                                  card_expire_year={this.state.card_expire_year}
                                  card_type={this.state.card_type}
                                  onChange={this.handleCardChange}
                                />
                              </Fragment>
                            ) : (
                              <Fragment>
                                <LabelStep>Okay, Using your credit card</LabelStep>
                                <CreditCardDetails
                                  card_account_number={
                                    '••••••••••••' + this.state.token_card_last_four
                                  }
                                  card_cvv={`•••`}
                                  card_expire_month={`••`}
                                  card_expire_year={`••`}
                                  card_type={this.state.token_card_type}
                                />
                              </Fragment>
                            )}
                            {!this.state.card_token ? (
                              <ButtonPrimary
                                onClick={this.handleCardSubmit}
                                disabled={!isValidCreditCard}
                              >
                                Continue
                              </ButtonPrimary>
                            ) : (
                              <ButtonGroup>
                                <ButtonGroupCircleX onClick={this.handleClearCreditCardClick} />
                                <ButtonPrimary
                                  onClick={() =>
                                    this.handleStepCompleteClick(STEP.GET_PAYMENT_INFO)
                                  }
                                >
                                  Continue
                                </ButtonPrimary>
                              </ButtonGroup>
                            )}
                          </Step>
                        )}
                      </Fragment>
                    )}

                    {isSummaryShown && (
                      <Summary
                        total={total}
                        fee={fee}
                        onLearnMoreClick={this.handleLearnMoreClick}
                        ref={this[STEP.GET_SUMMARY]}
                      />
                    )}
                  </FlowContainer>
                </Flow>
              </FlowContent>
            </ScrollContainer>
          </FlowBody>
          <FlowFooter>
            <Footer
              hideProgress={this.state.learnMoreShown}
              value={this.state.progress}
              max={5}
              handleGiveNowClick={this.handleGiveNowClick}
            />
          </FlowFooter>
        </PosedFlowWrapper>
        {this.state.learnMoreShown && (
          <LearnMoreOverlay>
            <H2>In the US alone, digital giving costs churches over $30 Million every year.</H2>
            <T1>
              That’s because when you give online, you think you’re giving $100, but instead the
              church (at best) receives just $96.80 after the platform and processor each take their
              cut. RebelGive is different. We’ve negotiated the lowest rates, we don’t take money
              from your gifts, and together with your help, we make sure all the costs of digital
              giving are fully covered. In this way, 100% of your intended gift always goes directly
              to your church as it should. Thank you so much for helping!
            </T1>
            <T1>
              Want to help even more? Using a bank account is easy and there are no
              convenience&nbsp;fees.
            </T1>
            <OverlayActions>
              <ButtonOptionPrimary onClick={this.handleLearnMoreCloseClick}>
                Back to Summary
              </ButtonOptionPrimary>
              <ButtonOptionSecondary onClick={this.handleUseBankAccountClick}>
                Or, use bank account
              </ButtonOptionSecondary>
            </OverlayActions>
          </LearnMoreOverlay>
        )}
      </Fragment>
    );
  }
}

export default GivingFlow;

const poseListContainerProps = {
  // enter: { staggerChildren: 50 },
  // exit: { staggerChildren: 20, staggerDirection: -1 },
};

const poseListItemProps = {
  // enter: { y: 0, opacity: 1 },
  // exit: { y: 50, opacity: 0 },
};

const FlowWrapper = styled.div`
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

const PosedFlowWrapper = posed(FlowWrapper)({
  init: { opacity: 1 },
  hide: { opacity: 0 },
});

const FlowBody = styled.div`
  position: relative;
  flex: 1;
`;

const ScrollContainer = styled.div`
  z-index: 2147483002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const FlowContent = styled(posed.div(poseListContainerProps))`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
`;

const Flow = styled(posed.div(poseListItemProps))`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 20rem;
  flex: 1 0 auto;
`;

const HORIZ_PAD = '1.2rem';

const FlowContainer = styled.div`
  width: 100%;
  max-width: calc(44rem + ${HORIZ_PAD} * 2);
  padding-left: ${HORIZ_PAD};
  padding-right: ${HORIZ_PAD};
  min-height: 32vh;

  ${media.tabletLandscapeAndUp`
    max-width: calc(58rem + ${HORIZ_PAD} * 2);
    min-height: 34vh;
  `}
`;

const FlowLogo = styled(posed(Logo.Container)(poseListItemProps))`
  flex: 0 0 auto;
  padding: 17vh 0 2rem;

  ${media.tabletLandscapeAndUp`
    padding: 14vh 0 2rem;
  `}
`;

const FlowFooter = styled.div`
  position: relative;
  z-index: 2147483004;
`;

const LearnMoreOverlay = styled(Prompt)`
  z-index: 2147483010;
  & > div {
    & > ${H2} {
      max-width: 12em;
      margin: 0 auto 1em;
      line-height: 1em;
    }

    & > ${T1} {
      max-width: 18.2em;
      margin: 1em auto 0;
    }

    ${media.tabletLandscapeAndUp`
      & > ${T1} {
        max-width: 31em;
      }
    `}
  }
`;

const OverlayActions = styled.div`
  margin: 3.6rem auto 0;
  max-width: 40rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGroupCircleX = styled(ButtonCircleX)`
  flex: 0 0 auto;
  height: 6rem;
  width: 6rem;
  margin-right: 1.6rem;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
