export const GIVINGFLOW_LS_STATE_KEY = '__givingflow_demo_state';

export const GIVING_FLOW_BASE_URL = process.env['REACT_APP_GIVING_FLOW_BASE_URL'];
export const CONTROL_CENTER_BASE_URL = process.env['REACT_APP_CONTROL_CENTER_BASE_URL'];

export const PLAID_PUBLIC_KEY = process.env['REACT_APP_PLAID_PUBLIC_KEY'];
export const PLAID_ENV = process.env['REACT_APP_PLAID_ENV'];

export const GIVING_FLOW_COLOR_MODE_LIGHT = 'GIVING_FLOW_COLOR_MODE_LIGHT';
export const GIVING_FLOW_COLOR_MODE_DARK = 'GIVING_FLOW_COLOR_MODE_DARK';
export const GIVING_FLOW_COLOR_MODES = [GIVING_FLOW_COLOR_MODE_LIGHT, GIVING_FLOW_COLOR_MODE_DARK];

export const GIVING_FLOW_BUTTON_POSITION_LEFT = 'GIVING_FLOW_BUTTON_POSITION_LEFT';
export const GIVING_FLOW_BUTTON_POSITION_RIGHT = 'GIVING_FLOW_BUTTON_POSITION_RIGHT';
export const GIVING_FLOW_BUTTON_POSITIONS = [
  GIVING_FLOW_BUTTON_POSITION_LEFT,
  GIVING_FLOW_BUTTON_POSITION_RIGHT,
];

export const GIVING_FLOW_ICON_GIVE = 'give';

export const SOURCE_TYPE_ACH_DEBIT = 'ach_debit';
export const SOURCE_TYPE_CARD = 'card';
export const SOURCE_TYPES = [SOURCE_TYPE_ACH_DEBIT, SOURCE_TYPE_CARD];
