import { ButtonCircleLogo, createPallette, createTheme } from '@foyyay/flow-elements';
import React, { Component } from 'react';
import { GIVING_FLOW_ICON_GIVE } from '../../constants';

export class Widget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }
  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
    window.parent.postMessage({ message: 'givingflow-launcher-loaded' }, '*');
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);
  }

  receiveMessage = (event) => {
    switch (event.data.message) {
      case 'receive-params':
        this.receiveParams(event.data.payload);
        break;

      default:
        break;
    }
  };

  receiveParams = (payload) => {
    let { Flow, Designations, SourceTypes, MerchantId } = payload;
    let palette = createPallette(Flow.color_flow);
    let theme = createTheme(palette, Flow.color_mode);
    this.setState({ loaded: true, ...payload });
    window.parent.postMessage(
      {
        message: 'givingflow-palette-generated',
        payload: {
          palette: palette,
          theme: theme,
        },
      },
      '*'
    );
    this.props.setTheme(theme);
  };

  handleOnClick = () => {
    window.parent.postMessage({ message: 'givingflow-launcher-clicked' }, '*');
  };

  render() {
    const triggerIcon = this.state.Flow !== undefined ? this.state.Flow.trigger_icon : GIVING_FLOW_ICON_GIVE;
    return <ButtonCircleLogo onClick={this.handleOnClick} triggerIcon={triggerIcon} />;
  }
}

export default Widget;
