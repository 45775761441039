import { ButtonCircleDownArrow, Header, media, Profile } from '@foyyay/flow-elements';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default class HeaderComponent extends Component {
  handleOnClick = () => {
    window.parent.postMessage({ message: 'givingflow-close-clicked' }, '*');
  };

  render() {
    let { isAuthenticated, userImageSrc } = this.props;

    return (
      <Header className={this.props.className}>
        <Header.CloseContainer>
          <StyledButtonCircleDownArrow onClick={this.handleOnClick} />
        </Header.CloseContainer>
        <Header.ProfileContainer>
          {isAuthenticated && (
            <Fragment>
              <AccountLink to="/my-account">
                <ButtonText>My Account</ButtonText>
                <Profile src={userImageSrc} />
              </AccountLink>
            </Fragment>
          )}

          {!isAuthenticated && (
            <Link to="/sign-in">
              <Header.ButtonText>Sign In</Header.ButtonText>
            </Link>
          )}
        </Header.ProfileContainer>
      </Header>
    );
  }
}

const StyledButtonCircleDownArrow = styled(ButtonCircleDownArrow)``;

const ButtonText = styled(Header.ButtonText)`
  display: none;

  ${media.tabletLandscapeAndUp`
    display: inline-block;
  `}
`;

const AccountLink = styled(Link)`
  white-space: nowrap;
  display: block;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${ButtonText} {
    cursor: pointer;
  }

  @media (hover: hover) {
    :hover {
      ${ButtonText} {
        opacity: 1;
      }
    }
  }
`;
